import http from './http'
import { pickBy } from 'lodash'

export function cities (params) {
  return http.get(`/cities`, { params })
}

export function search (params) {
  return http.get(`/search/hotels`, { params: pickBy(params) })
}

export function details (token) {
  return http.get(`/search/hotel/rooms/${token}`)
}

export function summary (token) {
  return http.get(`/search/hotel/summary/${token}`)
}
