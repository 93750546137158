<template>
  <q-card
    v-ripple
    class="cursor-pointer"
    @click.prevent="$router.push({ name: 'ondemand-hotel-details', params: { token: hotel.hotel_token } })"
  >
    <div class="hotel-card">
      <div class="hotel-image">
        <q-img
          :src="picture"
          :placeholder-src="require('assets/blank-hotel.png')"
        />
      </div>
      <div class="hotel-info">
        <div class="hotel-name">
          {{ hotel.name }}
        </div>
        <div class="hotel-rating">
          <q-rating
            v-model="rating"
            :max="5"
            class="row"
            readonly
            size="16px"
          />
        </div>
      </div>
      <div class="hotel-cost">
        <div class="hotel-price">
          <div class="price-before">
            {{ $t('hotel.rooms_from') }}
          </div>
          <div class="price">
            {{ hotel.min_price.text }}
          </div>
          <div class="price-after">
            {{ $t('hotel.price_per_night') }}
          </div>
        </div>
      </div>
    </div>
  </q-card>
</template>

<script type="text/javascript">

export default {
  name: 'HotelCard',
  props: {
    'hotel': null
  },
  computed: {
    picture () {
      return this.hotel.pictures[Object.keys(this.hotel.pictures).find(a => this.hotel.pictures[a].length > 0)] || '404'
    },
    rating () {
      return !this.hotel.rating ? 0 : this.hotel.rating
    },
    address () {
      if (Array.isArray(this.hotel.address.address)) {
        let address = this.hotel.address.address.slice(-2)
        return `${address[0]}, ${address[1]}`
      }

      return this.hotel.address.city
    }
  }
}
</script>

<style lang="stylus" scoped>
.hotel-card
  display grid
  gap 0.5rem
  grid-template-columns 1fr 2fr 1fr

  .hotel-image
    display grid
    height 90px

    .q-img
      height auto
      width 100%

  .hotel-info
    display grid
    grid-template-rows max-content max-content
    grid-template-columns 1fr

    .hotel-name
      font-weight bold
      color $blue-grey-6

  .hotel-cost
    display grid
    align-items center
    justify-content center

    .hotel-price
      display grid
      grid-template-columns 1fr
      color $blue-grey-6
      font-size 0.75rem
      text-align center

      .price
        font-size 1.5rem
</style>
