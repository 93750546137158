<template>
  <q-page padding class="row">
    <m-infinite-scroll
      :load="load"
      :page="stash.page"
      :pagination="stash.pagination"
      icon="hotel"
      resource="hotels"
    >
      <span v-for="(hotel, i) in stash.hotelList" :key="i">
        <hotel-card :key="hotel.hotel_token" :hotel="hotel" />
        <help-card v-if="(i === 2) || (i >= 8 && i % 8 === 0)" />
      </span>
    </m-infinite-scroll>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import date from 'utils/date-time'
import store from 'store'
import { search } from 'api/hotel'
import mInfiniteScroll from 'components/MInfiniteScroll'
import hotelCard from './hotel-card'
import helpCard from '../../shared/help-card'
import { handleErrors } from 'utils/utils'

export default {
  name: 'OndemandHotelResults',
  components: {
    hotelCard,
    mInfiniteScroll,
    helpCard
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    })
  },
  beforeDestroy (next) {
    store.dispatch('ondemand/stash', {
      hotelList: [],
      page: null,
      pagination: null
    })
  },
  methods: {
    async load (index) {
      store.dispatch('ondemand/stash', {
        page: index,
        loading: true
      })

      const { location, check_in, check_out, traveller, requester } = this.stash.parameters

      const params = {
        ...this.stash.parameters,
        page: this.stash.page,
        location: location.place_id || location.value,
        check_in: date.toCivilDate(check_in),
        check_out: date.toCivilDate(check_out),
        user: traveller.value,
        requester: requester.value
      }
      delete params.traveller
      if (!params.min_rating) {
        delete params.min_rating
      }

      try {
        const response = await search(params)

        const resData = response.data

        resData.sort((a, b) => {
          return a.min_price.amount - b.min_price.amount
        })

        store.dispatch('ondemand/stash', {
          hotelList: this.stash.hotelList.concat(resData),
          pagination: response.meta.pagination,
          loading: false
        })

        loading.stop()
        return resData
      } catch (err) {
        handleErrors(err)
        store.dispatch('ondemand/stash', {
          loading: false
        })
        loading.stop()
        // Throw error to MInfiniteScroll so it doesn't keep loading indefinitely
        throw err
      }
    }
  }
}
</script>
