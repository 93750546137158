var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.place)?_c('div',{staticClass:"relative-position thumbnail cursor-pointer",on:{"click":function($event){_vm.visible = true}}},[_c('div',{class:"label absolute-top-right"},[_vm._v(" View on map ")]),_c('m-gmaps-static',{attrs:{"zoom":14,"width":265,"height":100,"center":_vm.query,"markers":_vm.markers,"map-style":"feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:poi.business%7Cvisibility:off&style=feature:road%7Celement:labels%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&style=feature:transit%7Cvisibility:off&style=feature:water%7Celement:labels.text%7Cvisibility:off"}}),_c('q-dialog',{on:{"show":_vm.initMap,"hide":_vm.clearSelection},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticStyle:{"min-width":"80vw","width":"80vw","height":"80vh"}},[(_vm.radiusCenter)?_c('gmap-map',{ref:"map",attrs:{"center":_vm.radiusCenter,"zoom":14}},[_c('gmap-marker',{key:"radius-center",attrs:{"position":_vm.radiusCenter}}),_vm._l((_vm.radiuses),function(r){return _c('gmap-circle',{key:r.size,ref:'circle' + r.size,refInFor:true,attrs:{"radius":r.size,"center":_vm.radiusCenter,"options":{
            strokeColor: '#2057b2',
            fillColor: 'transparent',
            strokeOpacity: 0.5,
            strokeWeight: 4,
          }}})}),_vm._l((_vm.stash.hotelList),function(item){return _c('gmap-marker',{key:item.hotel_token,attrs:{"position":{lat: item.latitude, lng: item.longitude},"icon":"https://www.google.co.uk/maps/vt/icon/name=assets/icons/poi/quantum/container_background-2-medium.png,assets/icons/poi/quantum/container-2-medium.png,assets/icons/poi/quantum/lodging-2-medium.png&highlight=ffffff,388e3c,ffffff&color=ff000000?scale=1"},on:{"click":function($event){_vm.selected = item}}})}),(_vm.selected)?_c('gmap-info-window',{attrs:{"position":{lat: _vm.selected.latitude, lng: _vm.selected.longitude},"options":{
            zIndex: 100
          }},on:{"closeclick":function($event){_vm.selected = false}}},[_c('hotel-card',{staticClass:"gmap-hotel",attrs:{"hotel":_vm.selected}})],1):_vm._e()],2):_vm._e(),_c('q-btn',{staticClass:"bg-light-grey text-faded absolute",staticStyle:{"margin":"8px","top":"10vh","right":"calc(10vw + 50px)"},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" Back to hotel listing ")])],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }