<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="container">
      <q-card
        square
        class="bg-white"
      >
        <q-card-section :class="`bg-primary text-white text-uppercase`">
          {{ $t('where_would_you_like_to_stay') }}
        </q-card-section>
        <q-separator />
        <q-card-section class="column">
          <div class="row q-col-gutter-sm">
            <div class="col-12 col-sm-6">
              <m-location-search
                ref="pickUp"
                class="search-btn"
                :value="stash.parameters.location"
                :label="$t('input.city_name')"
                no-shadow
                keep-open
                @selected="locationSelect"
              />
            </div>
            <div class="col-12 col-sm-6">
              <q-select
                v-model="stash.parameters.adults"
                :options="options()"
                stack-label
                :label="$t('number_of.adults')"
                emit-value
                :outlined="$q.platform.is.mobile"
                :rounded="$q.platform.is.mobile"
              />
            </div>
            <div class="col-6">
              <m-date-field
                v-model="stash.parameters.check_in"
                :min="minCheckIn"
                :label="$t('check_in')"
                :outlined="$q.platform.is.mobile"
                :rounded="$q.platform.is.mobile"
              />
            </div>
            <div class="col-6">
              <m-date-field
                v-model="stash.parameters.check_out"
                :min="minCheckout"
                :max="maxCheckout"
                :label="$t('check_out')"
                :outlined="$q.platform.is.mobile"
                :rounded="$q.platform.is.mobile"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div class="cta">
        <m-cta-button
          :label="$tc('find.hotel', 2)"
          :disabled="$v.$invalid"
          @ctaClick="submit"
        />
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import 'styles/ondemand.styl'
import date from 'utils/date-time'
import { query } from 'api/places'
import { required } from 'vuelidate/lib/validators'
import model from './model'
import authentication from 'mixins/authentication'
import { MUnifiedUsers, MLocationSearch, MDateField, MCtaButton } from 'components/'
import { handleErrors } from 'utils/utils'

export default {
  name: 'OndemandHotel',
  components: {
    MDateField,
    MUnifiedUsers,
    MLocationSearch,
    MCtaButton
  },
  mixins: [ authentication ],
  data () {
    return {
      minCheckIn: date.newDate(),
      ...model()
    }
  },
  computed: {
    people: {
      get () {
        return {
          traveller: this.stash.parameters.traveller,
          requester: this.stash.parameters.requester
        }
      },
      set (val) {
        this.stash.parameters.requester = val.requester
        this.stash.parameters.traveller = val.traveller
      }
    },
    minCheckout () {
      const d = date.newDate(this.stash.parameters.check_in)
      return date.addToDate(d, { days: 1 })
    },
    maxCheckout () {
      const d = date.newDate(this.stash.parameters.check_in)
      return date.addToDate(d, { days: 89 })
    }
  },
  watch: {
    'stash.parameters.check_in' (val) {
      if (val.toISODate() >= this.stash.parameters.check_out.toISODate()) {
        this.stash.parameters.check_out = this.minCheckout
      }
    },
    '$route.query.location': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.stash.parameters.location = val
        }
      }
    }
  },
  validations: {
    stash: {
      parameters: {
        location: { required },
        traveller: { required },
        requester: { required }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  methods: {
    locationSelect (value) {
      this.stash.parameters.location = value
    },
    async autocomplete (terms) {
      try {
        const response = await query(terms)

        return response.data.map(location => {
          return {
            label: location.description,
            value: location.place_id,
            icon: 'place'
          }
        })
      } catch (err) {
        handleErrors(err)
      }
    },
    submit () {
      this.$store.dispatch('ondemand/stash', this.stash)
      this.$router.push({ name: 'ondemand-hotel-availability' })
    },
    options () {
      let options = []
      for (let i = 1; i < 11; i++) {
        options.push({ label: i.toString(), value: i })
      }
      return options
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
