<template>
  <img class="map" :src="url">
</template>

<script type="text/javascript">
import query from 'query-string'
export default {
  props: {
    center: null,
    zoom: {
      type: Number,
      default: 14,
      validator (value) {
        return value > 0 && value <= 21
      }
    },
    scale: {
      type: Number,
      default: 1,
      validator (value) {
        return [1, 2, 4].includes(value)
      }
    },
    format: {
      type: String,
      default: 'png8',
      validator (value) {
        return ['png', 'png8', 'png24', 'gif', 'jpg', 'jpg-baseline'].includes(value.toLowerCase())
      }
    },
    maptype: {
      type: String,
      default: 'roadmap',
      validator (value) {
        return ['roadmap', 'satellite', 'terrain', 'hybrid'].includes(value.toLowerCase())
      }
    },
    language: String,
    region: String,
    markers: String,
    path: String,
    visible: String,
    mapStyle: String,
    signature: String,
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 150
    }
  },
  computed: {
    url () {
      return `https://maps.googleapis.com/maps/api/staticmap?${this.queryString}`
    },
    queryString () {
      return query.stringify({
        ...this.$props,
        style: this.mapStyle,
        size: `${this.width}x${this.height}`,
        key: process.env.VUE_APP_GOOGLE_KEY
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.map
  max-width 100%
</style>
