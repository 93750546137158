<template>
  <router-link
    v-if="authPartner && authPartner.slug === 'derbygo'"
    :to="{hash: '/contact', query: { type: 'question' } }"
  >
    <q-banner ref="banner" dense rounded class="bg-primary q-mx-xs q-mt-md q-mb-sm q-pa-md">
      <template v-slot:avatar>
        <q-icon name="fas fa-comments-alt" size="32px" class="q-mr-sm" />
      </template>

      <div class="text-bold">
        {{ $t('derby.need_help') }}
      </div>
      <div class="text-subtitle2">
        {{ $t('derby.contact_us') }}
      </div>
    </q-banner>
  </router-link>
  <router-link
    v-else
    :to="{hash: '/concierge', query: { type: 'question' } }"
  >
    <q-banner ref="banner" dense rounded class="bg-primary q-mx-xs q-mt-md q-mb-sm q-pa-md">
      <template v-slot:avatar>
        <q-icon name="fas fa-concierge-bell" size="32px" class="q-mr-sm" />
      </template>

      <div class="text-subtitle2">
        {{ $t('cannot_find') }}
      </div>
      <div class="text-bold">
        {{ $t('concierge.always_here') }}
      </div>
    </q-banner>
  </router-link>
</template>

<script>
export default {
  computed: {
    authPartner () {
      return this.$store.getters['partner']
    }
  }
}
</script>
