<template>
  <div v-if="place" class="relative-position thumbnail cursor-pointer" @click="visible = true">
    <div :class="`label absolute-top-right`">
      View on map
    </div>
    <m-gmaps-static
      :zoom="14"
      :width="265"
      :height="100"
      :center="query"
      :markers="markers"
      map-style="feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:poi.business%7Cvisibility:off&style=feature:road%7Celement:labels%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&style=feature:transit%7Cvisibility:off&style=feature:water%7Celement:labels.text%7Cvisibility:off"
    />
    <q-dialog
      v-model="visible"
      @show="initMap"
      @hide="clearSelection"
    >
      <div style="min-width:80vw; width: 80vw; height: 80vh">
        <gmap-map v-if="radiusCenter" ref="map" :center="radiusCenter" :zoom="14">
          <gmap-marker key="radius-center" :position="radiusCenter" />
          <gmap-circle
            v-for="r in radiuses"
            :ref="'circle' + r.size"
            :key="r.size"
            :radius="r.size"
            :center="radiusCenter"
            :options="{
              strokeColor: '#2057b2',
              fillColor: 'transparent',
              strokeOpacity: 0.5,
              strokeWeight: 4,
            }"
          />
          <gmap-marker
            v-for="(item) in stash.hotelList"
            :key="item.hotel_token"
            :position="{lat: item.latitude, lng: item.longitude}"
            icon="https://www.google.co.uk/maps/vt/icon/name=assets/icons/poi/quantum/container_background-2-medium.png,assets/icons/poi/quantum/container-2-medium.png,assets/icons/poi/quantum/lodging-2-medium.png&highlight=ffffff,388e3c,ffffff&color=ff000000?scale=1"
            @click="selected = item"
          />
          <gmap-info-window
            v-if="selected"
            :position="{lat: selected.latitude, lng: selected.longitude}"
            :options="{
              zIndex: 100
            }"
            @closeclick="selected = false"
          >
            <hotel-card :hotel="selected" class="gmap-hotel" />
          </gmap-info-window>
        </gmap-map>
        <q-btn
          class="bg-light-grey text-faded absolute"
          style="margin:8px; top: 10vh; right: calc(10vw + 50px)"
          @click="visible = false"
        >
          Back to hotel listing
        </q-btn>
      </div>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { details } from 'api/places'
import hotelCard from '../../availability/hotel-card'
import mGmapsStatic from 'components/MGmaps/Static'
import { getGoogleMapsAPI } from 'gmap-vue'
import { handleErrors } from 'utils/utils'

let SnazzyInfoWindow

export default {
  name: 'MapModel',
  components: {
    hotelCard,
    mGmapsStatic
  },
  props: {
    query: String,
    markers: String,
    radius: Number
  },
  data () {
    return {
      selected: null,
      visible: false,
      place: null,
      radiuses: [],
      options: {
        disableDefaultUI: true,
        draggable: true,
        zoomControl: true
      }
    }
  },
  computed: {
    google: getGoogleMapsAPI,
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    radiusCenter () {
      if (!this.google) return
      return this.place ? new this.google.maps.LatLng(this.place.geometry.location.lat, this.place.geometry.location.lng) : null
    }
  },
  mounted () {
    const { place_id } = this.stash.parameters.location
    if (place_id) {
      details(this.stash.parameters.location.place_id)
        .then(response => {
          this.place = response.data

          this.$gmapApiPromiseLazy()
            .then(() => {
              const center = new this.google.maps.LatLng(this.place.geometry.location.lat, this.place.geometry.location.lng)
              this.radiuses = [
                {
                  size: 0.5 * 1610,
                  labelLatLng: this.computeOffset(center, 0.5 * 1610, 0),
                  labelText: '1/2 Mile'
                },
                {
                  size: 1 * 1610,
                  labelLatLng: this.computeOffset(center, 1 * 1610, 0),
                  labelText: '1 Mile'
                },
                {
                  size: 2 * 1610,
                  labelLatLng: this.computeOffset(center, 2 * 1610, 0),
                  labelText: '2 Miles'
                },
                {
                  size: 5 * 1610,
                  labelLatLng: this.computeOffset(center, 5 * 1610, 0),
                  labelText: '5 Miles'
                },
                {
                  size: 10 * 1610,
                  labelLatLng: this.computeOffset(center, 10 * 1610, 0),
                  labelText: '10 Miles'
                }
              ]
            })
            .then(() => {
              import('snazzy-info-window')
                .then(lib => {
                  SnazzyInfoWindow = lib.default
                })
            })
        })
        .catch(e => { handleErrors(e) })
    }
  },
  methods: {
    initMap () {
      this.$refs.map.resizePreserveCenter()
      this.radiuses.forEach(r => this.createInfoWindow(r))
    },
    clearSelection () {
      this.selected = null
    },
    computeOffset (from, distance, heading) {
      return this.google.maps.geometry.spherical.computeOffset(from, distance, heading)
    },
    createInfoWindow (r) {
      const info = new SnazzyInfoWindow({
        map: this.$refs.map.$mapObject,
        position: r.labelLatLng,
        content: r.labelText,
        closeOnMapClick: false,
        placement: 'top',
        panOnOpen: false
      })
      info.open()
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~snazzy-info-window/dist/snazzy-info-window.css'
.vue-map-container
  width 100%
  height 100%
.label
  font-size 12px
  padding 4px
  border-bottom-left-radius 4px
.gmap-hotel
  margin 0 !important
</style>

<style lang="stylus">
  .si-content-wrapper
    padding 8px
    border: 0!important
    box-shadow: 1px 1px 30px 0px rgba(0,0,0,0.3);
  .si-close-button, .si-pointer-top, .si-pointer-left
    display none

  .si-wrapper-top
    cursor pointer
</style>
