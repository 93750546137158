import date from 'utils/date-time'

export default function (route) {
  return {
    stash: {
      parameters: {
        traveller: null,
        requester: null,
        location: null,
        check_in: date.addToDate(date.newDate(), { minutes: 5 }),
        check_out: date.addToDate(date.newDate(), { minutes: 5, day: 1 }),
        rooms: 1,
        adults: 1,
        min_price: 0,
        max_price: null,
        min_rating: 0,
        hotel_name: null,
        max_rating: null,
        radius: 12880,
        limit: 25
      },
      hotel: null,
      rooms: null,
      selected: {},
      hotelList: [],
      pagination: null,
      loading: false,
      page: null,
      errors: false,
      options: {
        journey: {
          name: null,
          selected: null
        }
      }
    }
  }
}
