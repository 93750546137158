<template>
  <q-page
    v-if="stash.hotel"
    padding
  >
    <q-card class="bg-white">
      <q-card-section :class="`bg-primary text-white row`">
        <div class="col-9">
          <q-item-label label>
            {{ stash.hotel.name }}
          </q-item-label>
          <q-item-label v-if="stash.hotel.address" class="text-grey-4 text-body2">
            {{ stash.hotel.address.address | toAddressString }} {{ stash.hotel.address.city }}
          </q-item-label>
        </div>
        <div class="col-3 text-right text-h5">
          <q-rating
            v-model="rating"
            :max="5"
            readonly
            color="yellow"
          />
        </div>
      </q-card-section>
      <hotel-gallery v-if="pictures.length" :items="pictures" />
      <q-card-section v-else class="center items-center justify-center">
        <q-item-label caption class="center items-center justify-center">
          {{ $t('images_not_available') }}
        </q-item-label>
      </q-card-section>
    </q-card>
    <br>
    <q-banner rounded class="bg-primary" style="margin: 0 8px;">
      <div v-if="!showAll" class="results-type">
        <p>{{ $t('hotel.options.cheapest') }}</p>
        <q-btn :label="$t('show_all')" outline class="btn-all bg-white text-primary" @click="showAll = !showAll" />
      </div>
      <div v-else class="results-type">
        <p>{{ $t('hotel.options.all') }}</p>
        <q-btn :label="$t('show_cheapest')" outline class="btn-all bg-white text-primary" @click="showAll = !showAll" />
      </div>
    </q-banner>
    <div :class="!showAll ? 'grouped' : null">
      <div
        v-for="(list, type) in (showAll ? allRooms : groupedRooms)"
        :key="type"
        class="grouped-rooms"
      >
        <m-group-title class="type-name">
          {{ type }}
        </m-group-title>
        <q-card class="bg-white">
          <q-list>
            <div v-for="(r, i) in list" :key="i" class="q-pt-sm">
              <q-item clickable>
                <q-item-section>
                  <q-item-label>{{ r.meal_plan && `${r.meal_plan} - ` }}{{ r.description }}</q-item-label>
                  <q-item-label caption>
                    {{ r.rate_description }}
                  </q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-item-label class="text-black">
                    {{ r.total_price.text }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ r.total_stay.text }}
                  </q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-btn
                    color="primary"
                    class="full-width"
                    @click="$router.push({name: 'ondemand-hotel-summary', params: {token: r.room_token}})"
                  >
                    {{ $t('book_now') }}
                  </q-btn>
                </q-item-section>
              </q-item>
              <q-separator class="q-mt-sm" />
            </div>
          </q-list>
        </q-card>
      </div>
    </div>
    <br>
    <q-card class="bg-white">
      <q-card-section :class="`bg-primary text-white`">
        {{ $t('hotel.about', { hotel: stash.hotel.name }) }}
      </q-card-section>
      <q-separator />
      <q-card-section class="q-pa-none">
        <div
          v-if="stash.hotel.description"
          class="q-pa-sm"
          v-html="safeDescription"
        />
        <m-gmaps-static
          :zoom="15"
          :width="592"
          :height="225"
          class="q-pa-none row fit"
          :markers="`icon:https://www.google.co.uk/maps/vt/icon/name=assets/icons/poi/quantum/container_background-2-medium.png,assets/icons/poi/quantum/container-2-medium.png,assets/icons/poi/quantum/lodging-2-medium.png&highlight=ffffff,388e3c,ffffff&color=ff000000?scale=2|${stash.hotel.latitude},${stash.hotel.longitude}`"
          map-style="feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:poi.business%7Cvisibility:off&style=feature:road%7Celement:labels%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&style=feature:transit%7Cvisibility:off&style=feature:water%7Celement:labels.text%7Cvisibility:off"
        />
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script type="text/javascript">
import xss from 'xss'
import store from 'store'
import { mapGetters } from 'vuex'
import { details } from 'api/hotel'
import loading from 'utils/loading'
import hotelGallery from './hotel-gallery'
import i18n from 'i18n'
import { MGroupTitle, MGmapsStatic } from 'components/'
import { handleErrors } from 'utils/utils'

export default {
  name: 'OndemandHotelDetails',
  components: {
    hotelGallery,
    MGroupTitle,
    MGmapsStatic
  },
  filters: {
    toAddressString: (value) => Array.isArray(value) ? value.join(', ') : value + ', '
  },
  data () {
    return {
      index: null,
      showAll: false
    }
  },
  computed: {
    pictures () {
      return Object.values(this.stash.hotel.pictures).filter((photo, i, arr) => {
        return photo !== ''
      })
    },
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    safeDescription () {
      return xss(this.stash.hotel.description)
    },
    groupedRooms () {
      return this.stash.rooms.reduce((map, room) => {
        let roomType = map[room.room_type.trim()] = map[room.room_type.trim()] || {}
        if (!roomType[room.meal_plan] || room.total_price.amount < roomType[room.meal_plan].total_price.amount) {
          roomType[room.meal_plan] = room
        }
        return map
      }, {})
    },
    allRooms () {
      const types = {}

      this.stash.rooms.forEach(r => {
        if (!types[r.room_type]) {
          types[r.room_type] = []
        }

        types[r.room_type].push(r)
      })

      return types
    },
    rating () {
      return this.stash.hotel.rating || 0
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters['partner']

    loading.start({
      message: i18n.t('loading.checking.hotel_availability'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const response = await details(to.params.token)

      store.dispatch('ondemand/stash', {
        hotel: {
          ...response.data.hotel,
          hotel_token: to.params.token
        },
        rooms: response.data.rooms
      })

      next(() => loading.stop())
    } catch (err) {
      handleErrors(err)
      loading.stop()
      next(false)
    }
  }
}
</script>

<style lang="stylus" scoped>
.responsive
  height 100%
  background-size contain
  background-repeat no-repeat
  background-position center

.q-tab-pane > .q-carousel
  height 500px

.q-gallery-carousel
  width 100%
  max-height 500px

.type-name
  margin 15px

.results-type
  display flex
  justify-content space-between
  margin-left 15px
  @media screen and (max-width 600px) {
    flex-direction column
  }

.results-type p
  margin-top 8px

.btn-all
  align-self flex-end
</style>
