<template>
  <div class="hotel-gallery relative-position">
    <m-image
      :src="image"
      :fallback="require('assets/blank-hotel.png')"
      width="100%"
      style="min-height: 100px"
      class="row"
    />
    <div class="row absolute-top-left absolute-bottom-right">
      <div
        class="row col-5 cursor-pointer self-stretch"
        @click="previous"
      >
        <q-icon
          name="navigate_before"
          color="grey-4"
          size="96px"
        />
      </div>
      <div
        class="col-2 text-center self-end"
        style="margin-bottom: 8px"
      >
        <q-chip
          color="grey-4"
          small
        >
          {{ current }} / {{ total }}
        </q-chip>
      </div>
      <div
        class="row col-5 cursor-pointer self-stretch"
        style="justify-content: flex-end"
        @click="next"
      >
        <q-icon
          name="navigate_next"
          color="grey-4"
          size="96px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MImage } from 'components/'

export default {
  name: 'HotelGallery',
  components: { MImage },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      currentIndex: 0
    }
  },
  computed: {
    current () {
      return this.currentIndex + 1
    },
    total () {
      return this.items.length
    },
    image () {
      return this.items[this.currentIndex]
    }
  },
  methods: {
    next () {
      if (this.currentIndex < this.items.length - 1) {
        return this.currentIndex++
      }
      this.currentIndex = 0
    },
    previous () {
      if (this.currentIndex > 1) {
        return this.currentIndex--
      }
      this.currentIndex = this.items.length - 1
    }
  }
}
</script>
