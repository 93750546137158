<template>
  <q-drawer
    v-model="show"
    elevated
    side="right"
  >
    <div>
      <q-stepper
        v-if="!this.$q.platform.is.mobile"
        ref="stepper"
        v-model="currentStep"
        vertical
        class="row"
        header-nav
      >
        <q-step
          name="ondemand-hotel-availability"
          default
          :title="$t('hotel.choose.hotel')"
          :header-nav="navigatable.includes('ondemand-hotel-availability')"
          :done="navigatable.includes('ondemand-hotel-availability')"
        />
        <q-step
          name="ondemand-hotel-details"
          :title="$t('hotel.choose.room_type')"
          :header-nav="navigatable.includes('ondemand-hotel-details')"
          :done="navigatable.includes('ondemand-hotel-details')"
        />
        <q-step
          name="ondemand-hotel-summary"
          :title="$t('summary')"
          :caption="$t('review_and_submit')"
          :header-nav="navigatable.includes('ondemand-hotel-summary')"
          :done="navigatable.includes('ondemand-hotel-summary')"
        />
        <q-step
          name="ondemand-hotel-payment"
          :title="$t('payment')"
          :header-nav="navigatable.includes('ondemand-hotel-payment')"
          :done="navigatable.includes('ondemand-hotel-payment')"
        />
        <q-step
          name="ondemand-hotel-confirmation"
          :title="$t('confirmation')"
          :header-nav="navigatable.includes('ondemand-hotel-confirmation')"
          :done="navigatable.includes('ondemand-hotel-confirmation')"
        />
      </q-stepper>
      <q-list
        v-if="$route.name === 'ondemand-hotel-availability'"
        no-border
      >
        <q-item-section>
          <q-item-label header>
            {{ $t('search') }}
          </q-item-label>
        </q-item-section>
        <q-item>
          <q-item-section>
            <q-item-label>
              <m-select-query
                v-model="stash.parameters.location"
                :label="$t('city_location')"
                stack-label
                :placeholder="$t('input.city_name')"
                icon="hotel"
                :query="autocomplete"
                clearable
                @input="debouncedSearch"
              />
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              <div class="q-if-label" style="font-size:12px">
                {{ $t('radius_from_location', { radius: radius, unit: 'miles' }) }}
              </div>
              <q-slider
                v-model="radius"
                :step="2"
                :min="2"
                :max="30"
                :label-value="`${radius}${$t('abbreviation.kilometres')}`"
                :disable="stash.loading"
                snap
                markers
                @input="debouncedSearch"
              />
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              <m-date-field
                v-model="stash.parameters.check_in"
                :min="minCheckin"
                :label="$t('check_in')"
                @input="debouncedSearch"
              />
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              <m-date-field
                v-model="stash.parameters.check_out"
                :min="minCheckout"
                :max="maxCheckout"
                :label="$t('check_out')"
                @input="debouncedSearch"
              />
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item-label header>
          {{ $t('filter_results') }}
        </q-item-label>
        <q-item>
          <q-item-section>
            <q-item-label>
              <q-input
                v-model="name"
                stack-label
                :label="$t('hotel.name')"
                clearable
                @input="debouncedSearch"
              />
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label caption>
              {{ $t('your_budget') }}
            </q-item-label>
            <q-option-group
              v-model="priceRangesSelected"
              :options="priceRanges"
              :disable="stash.loading"
              type="checkbox"
              @input="debouncedSearch"
            />
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label caption>
              {{ $t('hotel.minimum_rating') }}
            </q-item-label>
            <q-rating
              v-model="rating"
              :min="0"
              :max="5"
              :disable="stash.loading"
              size="24px"
              @input="debouncedSearch"
            />
          </q-item-label>
        </q-item>
        <q-separator />
        <q-item>
          <q-item-label>
            <map-modal
              :query="stash.parameters.location ? stash.parameters.location.label : null"
              :radius="radius"
            />
          </q-item-label>
        </q-item>
      </q-list>
    </div>
  </q-drawer>
</template>

<script type="text/javascript">
import _ from 'lodash'
import { query } from 'api/places'
import { mapGetters } from 'vuex'
import mapModal from './map-modal'
import { MDateField, MSelectQuery } from 'components/'
import date from 'utils/date-time'

export default {
  name: 'OndemandHotelSidebar',
  components: {
    mapModal,
    MDateField,
    MSelectQuery
  },
  data () {
    return {
      show: !this.$q.platform.is.mobile,
      minCheckin: date.newDate(),
      radius: 8,
      rating: 0,
      name: null,
      selection: [],
      priceRanges: [
        {
          label: this.$t('cost.per_night', { num: '£0 - £43' }),
          value: { min: 0, max: 43 }
        },
        {
          label: this.$t('cost.per_night', { num: '£43 - £87' }),
          value: { min: 43, max: 87 }
        },
        {
          label: this.$t('cost.per_night', { num: '£87 - £130' }),
          value: { min: 87, max: 130 }
        },
        {
          label: this.$t('cost.per_night', { num: '£130 - £170' }),
          value: { min: 130, max: 170 }
        },
        {
          label: this.$t('cost.per_night', { num: '£170+' }),
          value: { min: 170, max: null }
        }
      ],
      priceRangesSelected: []
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      ui: 'ui'
    }),
    price () {
      let min = 9999
      let max = 0
      this.priceRangesSelected.forEach(p => {
        if (p.min < min) min = p.min
        if (p.max > max && max !== null) max = p.max
        if (p.max === null) max = null
      })
      return {
        min: (min < max) || (!max && min !== 9999) ? min : null,
        max: (max > min) ? max : null
      }
    },
    minCheckout () {
      const d = date.newDate(this.stash.parameters.check_in)
      return date.addToDate(d, { days: 1 })
    },
    maxCheckout () {
      const d = date.newDate(this.stash.parameters.check_in)
      return date.addToDate(d, { days: 89 })
    },
    currentStep: {
      get () {
        return this.$route.name
      },
      set (step) {
        this.$router.push({ name: step })
      }
    },
    navigatable () {
      let availablePaths = []
      switch (this.$route.name) {
      case 'ondemand-hotel-confirmation':
        availablePaths.push('ondemand-hotel-payment')
      case 'ondemand-hotel-payment':
        availablePaths.push('ondemand-hotel-summary')
      case 'ondemand-hotel-summary':
        availablePaths.push('ondemand-hotel-details')
      case 'ondemand-hotel-details':
        availablePaths.push('ondemand-hotel-availability')
        break
      }
      return availablePaths
    }
  },
  watch: {
    'stash.parameters.check_in' (val) {
      if (val.toISODate() >= this.stash.parameters.check_out.toISODate()) {
        this.stash.parameters.check_out = this.minCheckout
      }
    },
    radius (value, old) {
      this.stash.parameters.radius = value * 1610
    },
    price: {
      deep: true,
      handler (price, old) {
        this.stash.parameters.min_price = price.min
        this.stash.parameters.max_price = price.max
      }
    },
    rating (value, old) {
      this.stash.parameters.min_rating = value
    },
    name (value, old) {
      this.stash.parameters.hotel_name = value
    }
  },
  methods: {
    autocomplete (address) {
      return query(address)
        .then(response => {
          return response.data.map(location => {
            return {
              label: location.description,
              value: location.place_id,
              icon: 'place'
            }
          })
        })
    },
    debouncedSearch: _.debounce(function () {
      if (this.stash.parameters.location) {
        this.$store.dispatch('ondemand/stash', {
          hotelList: [],
          page: null,
          pagination: null
        })
      }
    }, 1000)
  }
}
</script>

<style lang="stylus" scoped>
.overview-price
  padding 8px
  .total
    font-size 1em
  .price
    margin 4px 0
    font-size 1.6em
  .people
    font-size 0.8em
</style>
